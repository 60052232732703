<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/bg-2.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            weight="regular"
            title="INSTANT YIELD GENERATION TOKEN PLATFORM"
          />

          <base-heading
            size="text-h4"
            title="FEED EVERY KOALA"
            weight="medium"
          />
          <p>
            We are a cousin project of <strong><a
              style="color: white"
              href="https://fegtoken.com/"
            > Feed Every Gorilla </a></strong> . We are amazed to see how <strong>$FEG </strong>  community grew up the past weeks and months. Big
            shout out to the team! The success is well deserved.
            We do not aim to be a competitor of <strong>$FEG </strong> . We like the model and we want to add utility features on top of the deflationary token.
            Apes are great, but koala are so cute ! 😉❤️
          </p>
          <p>
            While reflect tokens benefit from a strong tokenomics, they clearly suffer from a lack of utility. Holders get passive income, which is great, but they can't do anything with their tokens.
            Hence the creation of <strong>$FEK </strong>  which aims to gives utility features to the reflect token.

            <v-chip
              class="mt-2"
              color="pink"
              large
              label
              text-color="white"
              outlined
            >
              <strong> $FEK = $FEG + GAMING + CASHBACK + NFT </strong>
              <v-icon right>
                mdi-rocket-launch
              </v-icon>
            </v-chip>
          </p>
          <p>
            <br>
            <strong>$FEK </strong> works by applying fees to each transaction and instantly splitting part of that fee
            among all holders of the token. The rest is burnt.

            Holders do not need to stake or wait for fees to be delivered. Fees are awarded by the smart contract and
            are immediately reflected in the holders balance.
          </p>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn @click="buy">
              Buy $FEK on Pancake Swap
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import BaseBtn from '@/components/base/Btn'
  export default {
    name: 'SectionHero',
    components: { BaseBtn },
    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

    methods: {
      buy () {
        window.open('https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xcf8b87bd6c49cb0eb28b374d62fffc3922c8c77d')
      },
    },
  }
</script>
